import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import logo from "./logo3.png";

import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";

import DialogContent from "@material-ui/core/DialogContent";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  app: {
    backgroundColor: "#2196f3",
  },
  logo: {
    height: "40px",
    margin: "7px",
  },
  button: {
    //margin: "0.5vmin",
    background: "#eee",
    "& *": {
      textDecoration: "none !important",
    },
    clipPath: `polygon(
      0.5vmin 0.5vmin,                
      1.5vmin 0%,                 
      calc(100% - 1.5vmin) 0%,    
      calc(100% - 0.5vmin) 0.5vmin,               
      100% calc(100% - 0.5vmin),  
      calc(100% - 0.5vmin) 100%, 
      0.5vmin 100%,               
      0 calc(100% - 0.5vmin)     
    )`,
    "&:nth-child(2)": {
      clipPath: `polygon(
        0vmin 0.5vmin,                
      0.5vmin 0%,                 
      calc(100% - 0.5vmin) 0%,    
      calc(100% - 0vmin) 0.5vmin,               
      calc(100% - 0.5vmin) calc(100% - 0.5vmin),  
      calc(100% - 1.5vmin) 100%, 
      1.5vmin 100%,               
      0.5vmin calc(100% - 0.5vmin)      
      )`,
    },
  },
  button2: {
    //margin: "0.5vmin",
    background: "#eee",
    "& *": {
      textDecoration: "none !important",
    },
    clipPath: `polygon(
      0vmin 0.5vmin,                
    0.5vmin 0%,                 
    calc(100% - 0.5vmin) 0%,    
    calc(100% - 0vmin) 0.5vmin,               
    calc(100% - 0.5vmin) calc(100% - 0.5vmin),  
    calc(100% - 1.5vmin) 100%, 
    1.5vmin 100%,               
    0.5vmin calc(100% - 0.5vmin)      
    )`,
  },
}));

function SimpleDialog(props) {
  const classes = useStyles();
  const { handleClose, open } = props;

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth="xl"
    >
      <DialogTitle id="simple-dialog-title">Buy a playmat</DialogTitle>
      <DialogContent>
        <li>Download a playmat image</li>
        <li>
          Go to{" "}
          <a href="https://amzn.to/3uMVkRP" target="_blank" rel="noreferrer">
            Amazon
          </a>{" "}
          and buy this playmat using this{" "}
          <a href="https://amzn.to/3uMVkRP" target="_blank" rel="noreferrer">
            Affiliate Link
          </a>{" "}
          for a 24x14 playmat. This is a 3rd party vendor.
        </li>
      </DialogContent>
    </Dialog>
  );
}

function SimpleDialog2(props) {
  const classes = useStyles();
  const { handleClose, open } = props;

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth="xl"
    >
      <DialogTitle id="simple-dialog-title">Links</DialogTitle>
      <DialogContent>
        <li>
          Official Digimon Website{" "}
          <a
            href="https://world.digimoncard.com"
            target="_blank"
            rel="noreferrer"
          >
            World.Digimoncard.com
          </a>
        </li>
        <li>
          Discord{" "}
          <a href="https://discord.gg/yXsuBJs" target="_blank" rel="noreferrer">
            Digimon TCG Discord
          </a>
        </li>
        <li>
          Donate to Digimoncard.dev{" "}
          <a
            href="https://www.paypal.me/orangeswim"
            target="_blank"
            rel="noreferrer"
          >
            Paypal
          </a>
        </li>
        <li>
          Donate to Digimoncard.dev{" "}
          <a
            href="https://www.patreon.com/orangeswim"
            target="_blank"
            rel="noreferrer"
          >
            Patreon
          </a>
        </li>
      </DialogContent>
    </Dialog>
  );
}

export default function ButtonAppBar() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const [open2, setOpen2] = React.useState(false);

  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleOpen2 = () => {
    setOpen2(true);
  };

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.app}>
        <Toolbar>
          {/* <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
          >
            <MenuIcon />
          </IconButton> */}
          <img src={logo} className={classes.logo} />
          <Typography variant="h6" className={classes.title}>
            Playmats
          </Typography>
          {/* <Button color="inherit">Login</Button> */}
          <Button onClick={handleOpen2} className={classes.button2}>
            Links
          </Button>
          <Button className={classes.button} onClick={handleOpen}>
            Buy Playmat
          </Button>
          <Button
            href="https://digimoncard.dev"
            target="_blank"
            rel="noreferrer"
            className={classes.button2}
          >
            DeckBuilder
          </Button>
        </Toolbar>
      </AppBar>
      <SimpleDialog open={open} handleClose={handleClose} />
      <SimpleDialog2 open={open2} handleClose={handleClose2} />
    </div>
  );
}
