import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import { useCookies } from "react-cookie";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: "100%",
      "& > * + *": {
        //marginTop: theme.spacing(2),
      },
    },
  })
);

export default function TransitionAlerts({ clear, broken }) {
  const classes = useStyles();
  //const [open, setOpen] = React.useState(true);

  const [cookies, setCookie] = useCookies(["open", "open2"]);
  const open = cookies.open !== undefined ? cookies.open === "true" : true;
  const open2 = cookies.open2 !== undefined ? cookies.open2 === "true" : true;
  const open3 = broken;
  console.log(open, open2, cookies);
  return (
    <div className={classes.root}>
      <Collapse in={open}>
        <Alert
          severity="info"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              onClick={() => {
                //setOpen(false);
                setCookie("open", false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Please support the Official Release of Digimon TCG. <br />
          <a
            href="https://digimoncard.dev/privacy.html"
            target="_blank"
            rel="noreferrer"
          >
            Click here for our Privacy Policy
          </a>
          . DigimonCard.dev uses cookies, Google Analytics and Modern Browser
          storage to improve your experience.
        </Alert>
      </Collapse>
      <Collapse in={open2}>
        <Alert
          severity="info"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              onClick={() => {
                //setOpen(false);
                setCookie("open2", false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Do not redistribute images from DigimonCard.dev. Do not link directly
          to the images from the site. You may use the images for your personal
          use and content. This ensures that DigimonCard.dev runs smoothly and
          visitors have up to date information.
        </Alert>
      </Collapse>
      <Collapse in={open3}>
        <Alert
          severity="info"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              onClick={() => {
                //setOpen(false);
                setCookie("open2", false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Your deck information was corrupted. Click this button to clear your
          saved data on the server.{" "}
          <Button
            variant="contained"
            color="secondary"
            startIcon={<DeleteIcon />}
            onClick={clear}
          >
            Delete
          </Button>
        </Alert>
      </Collapse>
    </div>
  );
}
