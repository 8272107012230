import "./App.css";
import Appbar from "./Appbar";
import "typeface-roboto";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import Notice from "./notice";
import React, { Fragment } from "react";

var style = {
  margin: "2.5vmin",
};

class AdComponent extends React.Component {
  componentDidMount() {
    let width = window.innerWidth;
    (window.adsbygoogle = window.adsbygoogle || []).push({});
    if (width > 1456) {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
  }

  render() {
    let width = window.innerWidth;
    return (
      <Fragment>
        <div style={{ display: "flex", width: "100vw", minHeight: "100px" }}>
          <ins
            style={{
              display: "block",
            }}
            className="adsbygoogle ad adslot_1"
            data-ad-client="ca-pub-3897120385174936"
            data-ad-slot="9247193614"
            data-ad-format="auto"
            data-full-width-responsive="true"
          ></ins>
          {width > 1456 && (
            <ins
              className="adsbygoogle ad"
              style={{ display: "block", width: "728px", height: "100px" }}
              data-ad-client="ca-pub-3897120385174936"
              data-ad-slot="1065210677"
            ></ins>
          )}
        </div>
      </Fragment>
    );
  }
}

function App() {
  const [state, setState] = React.useState([]);
  //get json
  React.useEffect(() => {
    fetch("/playmats.json")
      .then((response) => response.json())
      .then((data) => {
        setState(data);
      });
  }, []);

  return (
    <div className="App">
      <div style={{ height: "50px" }}></div>
      <Notice />
      <SimpleReactLightbox>
        <Appbar />

        <AdComponent />
        <SRLWrapper>
          {state.map((a) => (
            <a
              href={`https://assets.cardlist.dev/playmats/${a.full}`}
              style={style}
            >
              <img
                src={`https://assets.cardlist.dev/playmats/thumb/${a.thumb}`}
                alt={a.alt}
              />
            </a>
          ))}
        </SRLWrapper>
      </SimpleReactLightbox>
    </div>
  );
}

export default App;
